<template>
    <ul class="c-ul--nobullet">
        <li v-for="(item, index) in list" :key="index">
            <router-link :to="{name:category, params: { keyword: item }}" class="nav-link">{{item}}</router-link>
        </li>
    </ul>
</template>
<script>
//import siteLoading from './siteloading.gif'

export default {
    props: {
        list: {
            type: Array,
        },
        category: {
            type: String,
        },
    },

    components: {

    },

    data() {
        return {
            media: null,
            siteLoading: null

        }

    },

    methods: {

    },
    created() {

        console.log(this.documentArray);
        //console.log(this.media);


    },





}
</script>