<template>
    <div class="p-main p-main--index container">
        <section>
            <h1 class="h5 text-center my-5">
               作者で探す</h1>
        </section>

        <section>
            <div class="c-appeare my-3">
                <div>
                    <ul class="p-indexlist p-indexlist--hiragana c-ul--nobullet">
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">あ</h2>
                                <IndexList :list="listA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">い</h2>
                                <IndexList :list="listI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">う</h2>
                                <IndexList :list="listU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">え</h2>
                                <IndexList :list="listE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">お</h2>
                                <IndexList :list="listO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">か</h2>
                                <IndexList :list="listKA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">き</h2>
                                <IndexList :list="listKI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">く</h2>
                                <IndexList :list="listKU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">け</h2>
                                <IndexList :list="listKE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">こ</h2>
                                <IndexList :list="listKO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">さ</h2>
                                <IndexList :list="listSA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">し</h2>
                                <IndexList :list="listSHI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">す</h2>
                                <IndexList :list="listSU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">せ</h2>
                                <IndexList :list="listSE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">そ</h2>
                                <IndexList :list="listSO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">た</h2>
                                <IndexList :list="listTA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ち</h2>
                                <IndexList :list="listCHI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">つ</h2>
                                <IndexList :list="listTSU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">て</h2>
                                <IndexList :list="listTE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">と</h2>
                                <IndexList :list="listTO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">な</h2>
                                <IndexList :list="listNA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">に</h2>
                                <IndexList :list="listNI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ぬ</h2>
                                <IndexList :list="listNU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ね</h2>
                                <IndexList :list="listNE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">の</h2>
                                <IndexList :list="listNO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">は</h2>
                                <IndexList :list="listHA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ひ</h2>
                                <IndexList :list="listHI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ふ</h2>
                                <IndexList :list="listFU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">へ</h2>
                                <IndexList :list="listHE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ほ</h2>
                                <IndexList :list="listHO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ま</h2>
                                <IndexList :list="listMA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">み</h2>
                                <IndexList :list="listMI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">む</h2>
                                <IndexList :list="listMU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">め</h2>
                                <IndexList :list="listME" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">も</h2>
                                <IndexList :list="listMO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ら</h2>
                                <IndexList :list="listRA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">り</h2>
                                <IndexList :list="listRI" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">る</h2>
                                <IndexList :list="listRU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">れ</h2>
                                <IndexList :list="listRE" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ろ</h2>
                                <IndexList :list="listRO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">や</h2>
                                <IndexList :list="listYA" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">ゆ</h2>
                                <IndexList :list="listYU" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">よ</h2>
                                <IndexList :list="listYO" :category="category"></IndexList>
                            </section>
                        </li>
                        <li class="p-indexlist__item">
                            <section>
                                <h2 class="p-indexlist__heading">わ</h2>
                                <IndexList :list="listWA" :category="category"></IndexList>
                            </section>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import IndexList from '../components/IndexList'



export default {
    components: {
        IndexList,

    },
    //API格納用のプロパティ
    data() {
        return {
            category: "dramatist",
            listA: ["秋田雨雀",
                "秋浜悟史",
                "秋元松代",
                "安部公房"
            ],
            listI: [
                "飯沢匡",
                "石川淳",
                "石澤秀二",
                "石原慎太郎",
                "泉鏡花",
                "市川森一",
                "井上光晴",
                "井上 靖",
            ],
            listU: [
                "内村直也",
                "宇野信夫"
            ],
            listE: [

                "遠藤周作",
            ],
            listO: [

                "大岡昇平",
                "大岡信",
                "大西信行",
                "岡本綺堂",
                "小山内薫",
                "小幡欣治"
            ],
            listKA: [
                "加藤道夫",

            ],
            listKI: [
                "岸田國士",
                "木下順二",
            ],
            listKU: [
                "久保栄",
                "久保田万太郎",
                "黒井千次",
            ],
            listKE: [

            ],
            listKO: [
                "小山祐士",
            ],
            listSA: [
                "斎藤憐",
                "斎藤憐",
                "佐木隆三",
            ],
            listSHI: [
                "椎名麟三",
                "清水邦夫",
            ],
            listSU: [
                "菅原卓",
            ],
            listSE: [

            ],

            listSO: [

            ],
            listTA: [
                "田口竹男",
                "田中澄江",
                "田中千禾夫",
                "谷崎潤一郎",
            ],
            listCHI: [],
            listTSU: [
                "筒井康隆",

            ],
            listTE: [

            ],
            listTO: [
                "戸板康二",
                "富岡多恵子",
            ],
            listNA: [
                "中村光夫",
            ],
            listNI: [
                "西島大",
            ],
            listNU: [

            ],
            listNE: [

            ],
            listNO: [

            ],
            listHA: [
                "長谷川四郎",
            ],
            listHI: [
                "久板栄二郎",
            ],
            listFU: [
                "福田恆存",
                "福田善之",
            ],
            listHE: [

            ],
            listHO: [
                "堀田善衛",
            ],
            listMA: [
                "森本薫",
                "松本清張",
                "真船豊",
            ],
            listMI: [
                "三島由紀夫",
                "水上勉",
                "水木洋子",
                "宮本研",
                "三好十郎",
            ],
            listMU: [
                "村山知義",
            ],
            listME: [

            ],
            listMO: [

            ],
            listYA: [
                "八木柊一郎",
                "矢代静一",
                "安岡章太郎",
                "山崎正和",
                "山田太一",
                "山本有三",
            ],
            listYU: [

            ],
            listYO: [
                "吉永仁郎",
            ],
            listRA: [

            ],
            listRI: [

            ],
            listRU: [

            ],
            listRE: [

            ],
            listRO: [

            ],
            listWA: [

            ]


        }
    },

}
</script>